import 'intersection-observer'; // polyfill
import balloon from './balloon';

const keyvisual = {
  el: null,
  balloonEl: null,
  timer: null,
};

function animate() {
  return new Promise(resolve => {
    keyvisual.balloonEl.addEventListener('transitionend', resolve);
  });
}

function start() {
  // if (!balloon.el) {
  //   return;
  // }
  keyvisual.timer = setInterval(() => {
    balloon.update();
  }, 3000);
}

function stop() {
  clearInterval(keyvisual.timer);
  keyvisual.timer = null;
}

function onIntersect(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      start();
    } else {
      stop();
    }
  });
}

function observe() {
  const observer = new IntersectionObserver(onIntersect, {
    rootMargin: `0%`,
  });
  observer.observe(keyvisual.el);
}

async function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    keyvisual.el = el;
    keyvisual.balloonEl = keyvisual.el.querySelector('.p-keyvisual__balloon');
    balloon.initialize('.js-balloon');
    await animate();
    // 要素が画面内になるかどうかを見て吹き出しアニメーションを制御
    observe();
  }
}

export default { initialize };
