import PubSub from 'pubsub-js';

const hamburger = {
  el: null,
};

function onClick(e) {
  e.preventDefault();
  PubSub.publish('hamburger:click', {
    target: 'menu',
  });
}

function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    hamburger.el = el;
    hamburger.el.addEventListener('click', onClick);
  }
}

export default { initialize };
