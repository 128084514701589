import Swiper from 'swiper/js/swiper';

const carousel = {
  el: null,
};

function initialize(selector) {
  const el = document.querySelector(selector);
  return new Promise(resolve => {
    if (el) {
      carousel.el = el;
      // eslint-disable-next-line no-new
      new Swiper('.swiper-container', {
        slidesPerView: 3,
        loop: true,
        loopFillGroupWithBlank: true,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
        },
        speed: 800,
        on: {
          init() {
            carousel.el.classList.add('is-initialized');
            resolve();
          },
        },
      });
    } else {
      resolve();
    }
  });
}

export default { initialize };
