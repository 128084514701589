import 'intersection-observer'; // polyfill
import PubSub from 'pubsub-js';
import view from '../plugins/view';

const footlink = {
  el: null,
  pagetopElement: null,
  isPagetopHidden: true,
};

let windowHeight = window.innerHeight;
let observer = null;

function toggleVisibility(isVisible) {
  footlink.isPagetopHidden = !isVisible;
  footlink.pagetopElement.setAttribute('aria-hidden', !isVisible);
}

function onIntersect(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      footlink.pagetopElement.dataset.fixed = false;
    } else {
      footlink.pagetopElement.dataset.fixed = true;
    }
  });
}

function setPagetopFixationOnPC() {
  const BOTTOM_SPACING = 20;
  const POINT_TO_FIX = Math.ceil(37 + BOTTOM_SPACING);
  observer = new IntersectionObserver(onIntersect, {
    rootMargin: `0% 0% -${POINT_TO_FIX}px`,
  });
  observer.observe(footlink.el);
}

function setPagetopFixationOnSP() {
  observer = new IntersectionObserver(onIntersect, {
    rootMargin: `0% 0% 0%`,
    threshold: 1,
  });
  observer.observe(footlink.el);
}

function onScroll(e, { pageYOffset }) {
  if (pageYOffset > windowHeight) {
    if (footlink.isPagetopHidden) {
      toggleVisibility(true);
    }
  } else if (!footlink.isPagetopHidden) {
    toggleVisibility(false);
  }
}

function onResize(e, { height, isMobile }) {
  windowHeight = height;
  if (isMobile) {
    observer.disconnect();
    setPagetopFixationOnSP();
  } else {
    observer.disconnect();
    setPagetopFixationOnPC();
  }
}

function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    footlink.el = el;
    footlink.pagetopElement = el.querySelector('.js-footlink-pagetop');
    if (view.isMobile) {
      setPagetopFixationOnSP();
    } else {
      setPagetopFixationOnPC();
    }
    PubSub.subscribe('view:scroll', onScroll);
    PubSub.subscribe('view:resize', onResize);
  }
}

export default { initialize };
