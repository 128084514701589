import WebFont from 'webfontloader';

function load() {
  return new Promise((resolve, reject) => {
    WebFont.load({
      timeout: 5000,
      google: {
        // 300はフッターのみで使用しているため読み込み完了は待たずでOK
        // families: ['Noto+Sans+JP:300,400,700'],
        families: ['Noto+Sans+JP:400,700'],
      },
      active: resolve,
      inactive: reject,
    });
  });
}

export default { load };
