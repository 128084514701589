const faq = {
  elements: [],
};

function shrink(el) {
  const container = el;
  container.style.height = 0;
}

function expand(el) {
  const container = el;
  const content = container.querySelector('.js-faq-content');
  const height = content.clientHeight;
  container.style.height = `${height}px`;
}

function initialize(selector) {
  const els = document.querySelectorAll(selector);
  if (els) {
    faq.elements = els;
    faq.elements.forEach(el => {
      const trigger = el.querySelector('.js-faq-trigger');
      const container = el.querySelector('.js-faq-container');
      trigger.addEventListener('click', () => {
        const isExpanded = el.getAttribute('aria-expanded') === 'true';
        if (isExpanded) {
          shrink(container);
        } else {
          expand(container);
        }
        el.setAttribute('aria-expanded', !isExpanded);
      });
    });
  }
}

export default { initialize };
