const movie = {
  el: null,
  player: null,
  playButton: null,
  closeButton: null,
  isOpen: false,
};

function onClickPlay(e) {
  console.log('play');
  document.body.classList.add('is-modal');
  e.preventDefault();
  const id = movie.playButton.dataset.youtubeId;
  movie.player.setAttribute(
    'src',
    `https://www.youtube.com/embed/${id}?controls=0&autoplay=1`,
  );
  movie.el.setAttribute('aria-hidden', false);
}

function onClickClose(e) {
  console.log('play');
  document.body.classList.remove('is-modal');
  e.preventDefault();
  movie.el.setAttribute('aria-hidden', true);
  movie.player.removeAttribute('src');
}

function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    movie.el = el;
    movie.player = el.querySelector('.js-movie-player');
    movie.playButton = document.querySelector('.js-movie-button');
    movie.closeButton = el.querySelector('.js-movie-close');
    movie.playButton.addEventListener('click', onClickPlay);
    movie.closeButton.addEventListener('click', onClickClose);
  }
}

export default { initialize };
