import lozad from 'lozad/dist/lozad';
import keyvisual from './keyvisual';
import carousel from './carousel';
import movie from './movie';

const page = {
  el: null,
};

async function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    page.el = el;
    const observer = lozad('.js-inview', {
      rootMargin: '0% 0% -33.3%',
    });
    observer.observe();
    keyvisual.initialize('.js-keyvisual');
    carousel.initialize('.js-carousel');
    movie.initialize('.js-movie');
  }
}

export default { initialize };
