import { TweenMax, Quad, Elastic } from 'gsap';

const balloon = {
  el: null,
  items: [],
  index: 0,
  current: null,
  next: null,
};

function getShuffledItems(selector) {
  // 手書き文字画像の順番をシャッフルし表示順序を毎回ランダムにする
  // シャッフルの実装は下記を参照
  // https://gist.github.com/guilhermepontes/17ae0cc71fa2b13ea8c20c94c5c35dc4#gistcomment-2271465
  return [...balloon.el.querySelectorAll(selector)]
    .map(a => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map(a => a[1]);
}

function getItemsDom(items = []) {
  const fragment = document.createDocumentFragment();
  items.forEach(item => {
    fragment.appendChild(item);
  });
  return fragment;
}

function replaceItems(newItems) {
  balloon.el.innerHTML = '';
  balloon.el.appendChild(newItems);
}

function update() {
  TweenMax.to(balloon.current, 0.12, {
    y: -150,
    ease: Quad.easeOut,
    onStart() {
      balloon.current.classList.remove('is-current');
    },
  });
  TweenMax.fromTo(
    balloon.next,
    1,
    {
      y: 15,
    },
    {
      y: 0,
      ease: Elastic.easeOut,
      delay: 0.2,
      onStart() {
        balloon.next.classList.add('is-current');
      },
      onComplete() {
        if (balloon.index >= balloon.items.length - 1) {
          balloon.index = 0;
          balloon.current = balloon.items[balloon.index];
          balloon.next = balloon.items[balloon.index + 1];
        } else {
          balloon.index += 1;
          if (balloon.index === balloon.items.length - 1) {
            balloon.current = balloon.items[balloon.index];
            // eslint-disable-next-line prefer-destructuring
            balloon.next = balloon.items[0];
          } else {
            balloon.current = balloon.items[balloon.index];
            balloon.next = balloon.items[balloon.index + 1];
          }
        }
      },
    },
  );
}

function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    balloon.el = el;
    balloon.items = getShuffledItems('.js-balloon-item');
    replaceItems(getItemsDom(balloon.items));
    balloon.current = balloon.items[balloon.index];
    balloon.next = balloon.items[balloon.index + 1];
    balloon.current.classList.add('is-current');
  }
}

export default { initialize, update };