import { throttle, debounce } from 'throttle-debounce';
import PubSub from 'pubsub-js';

const BREAKPOINT = 767;

let width = 0;
let height = 0;
let pageYOffset = 0;
let isMobile = false;

export default {
  get width() {
    return width;
  },
  get height() {
    return height;
  },
  get pageYOffset() {
    return pageYOffset;
  },
  get isMobile() {
    return isMobile;
  },
};

function onScroll() {
  pageYOffset = window.pageYOffset;
  PubSub.publish('view:scroll', { pageYOffset });
}

function onResize() {
  width = document.documentElement.clientWidth;
  height = window.innerHeight;
  isMobile = window.matchMedia(`(max-width: ${BREAKPOINT}px)`).matches;
  PubSub.publish('view:resize', { width, height, isMobile });
}

window.addEventListener('scroll', throttle(300, onScroll));
window.addEventListener('resize', debounce(500, onResize));

onScroll();
onResize();
