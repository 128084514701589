import PubSub from 'pubsub-js';

const entryButton = {
  el: null,
};

function onClick(e) {
  e.preventDefault();
  PubSub.publish('entryButton:click', {
    target: 'entry',
  });
}

function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    entryButton.el = el;
    entryButton.el.addEventListener('click', onClick);
  }
}

export default { initialize };
