import PubSub from 'pubsub-js';
import entryButton from './entryButton';
import hamburger from './hamburger';
import view from '../plugins/view';

const navigation = {
  elements: [],
  target: null,
  isOpen: false,
};

function open(el) {
  navigation.isOpen = true;
  navigation.target = el;
  navigation.target.setAttribute('aria-hidden', false);
  navigation.target.style.height = `${view.height}px`;
  PubSub.publish('navigation:open', {
    isOpen: navigation.isOpen,
  });
}

function close() {
  if (navigation.isOpen) {
    navigation.isOpen = false;
    const onTransitionend = () => {
      navigation.target.querySelector('.js-navigation-content').scrollTo(0, 0);
      navigation.target.removeEventListener('transitionend', onTransitionend);
      navigation.target = null;
    };
    navigation.target.addEventListener('transitionend', onTransitionend);
    navigation.target.setAttribute('aria-hidden', true);
    PubSub.publish('navigation:open', {
      isOpen: navigation.isOpen,
    });
  }
}

function onClickButton(e, { target }) {
  const element = navigation.elements.find(el => {
    return el.dataset.type === target;
  });
  open(element);
}

function onClickClose(e) {
  e.preventDefault();
  close();
}

function onResize(e, { height }) {
  if (navigation.isOpen) {
    navigation.target.style.height = `${height}px`;
  }
}

function initialize(selector) {
  const elements = document.querySelectorAll(selector);
  if (elements.length) {
    navigation.elements = [...elements];
    navigation.elements.forEach(el => {
      const closeButton = el.querySelector('.js-navigation-close');
      closeButton.addEventListener('click', onClickClose);
    });
    PubSub.subscribe('entryButton:click', onClickButton);
    PubSub.subscribe('hamburger:click', onClickButton);
    PubSub.subscribe('view:resize', onResize);
    entryButton.initialize('.js-entryButton');
    hamburger.initialize('.js-hamburger');
  }
}

export default { initialize };
