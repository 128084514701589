import imagesLoaded from 'imagesloaded';

function load(selector) {
  return new Promise(resolve => {
    imagesLoaded(selector, instance => {
      resolve(instance);
    });
  });
}

export default { load };
