import lozad from 'lozad/dist/lozad';

const page = {
  el: null,
  headerLastChild: null,
};

function getHeaderLastChild() {
  const leadEl = page.el.querySelector('.l-page__lead');
  return leadEl || page.el.querySelector('.l-page__heading');
}

function animateHeader() {
  if (page.headerLastChild) {
    return new Promise(resolve => {
      page.headerLastChild.addEventListener('transitionend', resolve);
      page.el.classList.add('is-initialized');
    });
  }
  return Promise.resolve();
}

async function initialize(selector) {
  const el = document.querySelector(selector);
  if (el) {
    page.el = el;
    page.headerLastChild = getHeaderLastChild();
    await animateHeader();
    const observer = lozad('.js-inview', {
      rootMargin: '0% 0% -33.3%',
    });
    observer.observe();
  }
}

export default { initialize };
