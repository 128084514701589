import 'lazysizes';
import 'default-passive-events';
import PubSub from 'pubsub-js';
import objectFitImages from 'object-fit-images';

import './plugins/scroller';
import './plugins/view';
import assign from 'es6-object-assign';
import imageLoader from './plugins/imageloader';
import fontLoader from './plugins/fontloader';

import navigation from './modules/navigation';
import index from './modules/index';
import page from './modules/page';
import footlink from './modules/footlink';
import faq from './modules/faq';

assign.polyfill();

function onNavigationToggle(e, { isOpen }) {
  if (isOpen) {
    document.body.classList.add('is-modal');
  } else {
    document.body.classList.remove('is-modal');
  }
}

window.addEventListener('DOMContentLoaded', async () => {
  navigation.initialize('.js-navigation');
  footlink.initialize('.js-footlink');
  PubSub.subscribe('navigation:open', onNavigationToggle);
  const image = imageLoader.load('.js-preload');
  const font = fontLoader.load();
  await font;
  await image;
  index.initialize('.js-index');
  page.initialize('.js-page');
  faq.initialize('.js-faq');
  objectFitImages('img.js-fit');
});
